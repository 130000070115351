<template>
	<div class="flex flex-col space-y-12">

    <div class="text-black xl:container mx-auto">
      <p class="md:text-xl text-orange md:w-4/6">{{ $t("acssa.res_desc") }}</p>
      <p class="text-primary mt-4">{{ $t("acssa.res_desc_sub") }}</p>
      <p class="md:w-4/6">{{ $t("acssa.res_desc_sub_2") }}</p>
    </div>

		<div class="w-full xl:container mx-auto">

    <div class="flex flex-col md:flex-row justify-between md:items-center relative z-10 bg-orange text-white p-2.5 md:py-7 md:pl-4 md:pr-2.5">
      <div class="font-bold text-xl md:text-2xl mb-2.5 md:mb-0">
        <p>{{ $t("acssa.res_score_typical") }}</p>
      </div>
      <div class="w-full md:w-auto md:min-w-xs bg-white text-orange rounded pl-4 py-3.5">
        <span>{{ calcScore(scores["1"]) }} {{ $t("acssa.res_points") }}</span>
      </div>
    </div>

      <div class="mt-2.5 border border-primary">
        <div class="border border-orange text-orange bg-orange bg-opacity-20">
          <div class="flex md:justify-evenly h-full items-center pl-2.5 pt-2.5 pb-4">
            <p>{{ $t("acssa.res_sum_score_typical") }}</p>
            <span class="w-px h-full bg-orange block"></span>
            <p class="hidden md:block"> {{ $t("acssa.res_probability_ac") }}</p>
          </div>
        </div>

        <div class="flex flex-col md:divide-y divide-primary text-xl">
          <div
            class="flex flex-col md:flex-row md:justify-between md:divide-x divide-primary md:pt-6 md:pb-4 md:px-4 bg-opacity-20"
            :class="{ 'bg-orange': calcScore(scores['1']) <= 5 }"
          >
            <div class="p-2.5 md:w-6/12 md:text-center">
              <p>
                <span class="md:hidden">{{ $t("acssa.res_sum_score_typical") }} <br></span>
                <span class="text-orange md:text-primary">0 - 5</span>
              </p>
            </div>
            <div class="p-2.5 md:w-6/12 md:text-center">
              <p>
                <span class="md:hidden">{{ $t("acssa.res_probability_ac") }} <br></span>
                <span class="text-orange md:text-primary">{{ $t("acssa.low") }}</span>
              </p>
            </div>
          </div>

          <div 
            class="flex flex-col md:flex-row md:justify-between md:divide-x divide-primary md:pt-6 md:pb-4 md:px-4 bg-opacity-20"
            :class="{ 'bg-orange': calcScore(scores['1']) <= 8 && calcScore(scores['1']) > 5 }"
          >
            <div class="p-2.5 md:w-6/12 md:text-center">
              <p>
                <span class="md:hidden">{{ $t("acssa.res_sum_score_typical") }} <br></span>
                <span class="text-orange md:text-primary">6 - 8</span>
              </p>
            </div>
            <div class="p-2.5 md:w-6/12 md:text-center">
              <p>
                <span class="md:hidden">{{ $t("acssa.res_probability_ac") }} <br></span>
                <span class="text-orange md:text-primary">{{ $t("acssa.moderate") }}</span>
              </p>
            </div>
          </div>

          <div
            class="flex flex-col md:flex-row md:justify-between md:divide-x divide-primary md:pt-6 md:pb-4 md:px-4 bg-opacity-20"
            :class="{ 'bg-orange': calcScore(scores['1']) >= 9 }"
          >
            <div class="p-2.5 md:w-6/12 md:text-center">
              <p>
                <span class="md:hidden">{{ $t("acssa.res_sum_score_typical") }} <br></span>
                <span class="text-orange md:text-primary">&ge; 9</span>
              </p>
            </div>
            <div class="p-2.5 md:w-6/12 md:text-center">
              <p>
                <span class="md:hidden">{{ $t("acssa.res_probability_ac") }} <br></span>
                <span class="text-orange md:text-primary">{{ $t("acssa.high") }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full xl:container mx-auto">
      <div class="flex flex-col md:flex-row justify-between md:items-center relative z-10 bg-orange text-white p-2.5 md:py-7 md:pl-4 md:pr-2.5">
        <div class="font-bold text-xl md:text-2xl mb-2.5 md:mb-0">
          <p>{{ $t("acssa.res_score_differential") }}</p>
        </div>
        <div class="w-full md:w-auto md:min-w-xs bg-white text-orange rounded pl-4 py-3.5">
          <span>{{ calcScore(scores["2"]) }} {{ $t("acssa.res_points") }}</span>
        </div>
      </div>
		</div>

    <div class="w-full xl:container mx-auto">
      <div class="flex flex-col md:flex-row justify-between md:items-center relative z-10 bg-orange text-white p-2.5 md:py-7 md:pl-4 md:pr-2.5">
        <div class="font-bold text-xl md:text-2xl mb-2.5 md:mb-0">
          <p>{{ $t("acssa.res_score_qol") }}</p>
        </div>
        <div class="w-full md:w-auto md:min-w-xs bg-white text-orange rounded pl-4 py-3.5">
          <span>{{ calcScore(scores["3"]) }} {{ $t("acssa.res_points") }}</span>
        </div>
      </div>
		</div>

    <div
      class="mx-auto w-full max-w-xs p-4 text-center text-bold bg-white text-orange border border-orange border-2 hover:text-white hover:bg-orange transition duration-300 ease-in-out cursor-pointer"
      @click="getPdf"
    >
    <p>{{ $t("home.download_pdf") }}</p>
    </div>

	</div>
</template>

<script>
import useScore from '@/composables/useScore'
import useCreateAcssAPdf from '@/composables/useCreateAcssAPdf'

export default {
  name: 'ResultAcssA',
  setup() {
    const { scores, calcScore } = useScore()
    const { getPdf } = useCreateAcssAPdf()

    return {
      scores,
      calcScore,
      getPdf
    }
  }
}
</script>
