<template>
	<div class="pt-10">

		<img
			class="absolute top-0 right-0 z-0"
			:src="require('@/assets/bg-circle.png')"
			style="width: 19.68vw; max-width: 178px"
		/>

		<h1 class="text-3xl md:text-5xl xl:container z-10 text-primary mb-6 mx-auto md:mb-12">
			{{ $t("acssa.title") }}
			<span class="text-orange">{{ $t("acssa.title_sub") }}</span>
		</h1>

		<div v-if="!isLastPage()">

			<div class="w-full xl:container mx-auto relative z-10">
				<div class="flex flex-col md:flex-row md:items-center justify-between md:border border-orange md:py-9 md:pl-4">
					<div>
						<p class="text-base md:text-2xl font-bold text-orange">
						{{ $t("acssa.first_visit") }}
						</p>
					</div>
					<div class="flex flex-col md:flex-row text-orange">
						<div class="p-4 border rounded border-orange my-2 md:my-0 md:mx-4">{{ time_f }}</div>
						<div class="p-4 border rounded border-orange md:mx-4">{{ date_f }}</div>
					</div>
				</div>

				<span class="md:hidden w-full h-px block opacity-10  bg-primary my-5"></span>

				<div class="pb-5 md:py-12">
					<h2 class="text-3xl text-orange">{{ pageData.subtitle }}</h2>
					<p>{{ pageData.description }}</p>
				</div>
			</div>

			<div class="flex flex-col space-y-5">
				<Question
					v-for="q in pageData.questions"
					:key="q.id"
					:question="q"
					:page="pageData.id"
					:error="errors?.[q.id]"
					:scores="scores"
					@update="updateScore"
				/>
			</div>

		</div>
		<div v-else>
			<ResultAcssA />
		</div>

		<Alert :message="$t('acssa.error_desc')" :show="alertVisible" />

		<TheFooter 
			@next="nextPage"
			@prev="prevPage"
			:currPage="currPage"
			:totalPages="totalPages"
		/>

	</div>
</template>

<script>
import { ref, watch } from 'vue'
import Question from '@/components/Question'
import ResultAcssA from '@/views/ResultAcssA'
import TheFooter from '@/components/TheFooter'
import useData from '@/composables/useData'
import useScore from '@/composables/useScore'
import useTimestamp from '@/composables/useTimestamp'
import Alert from '@/components/Alert'

export default {
	name: 'AcssA',
	components: {
		Question,
		ResultAcssA,
		TheFooter,
		Alert
	},
	setup() {
    const { isLoaded, pageData, getPage, totalPages } = useData('part_a')
		const { init, errors, validate, scores, addToScore } = useScore('part_a')
		const { date_f, time_f } = useTimestamp()

		const currPage = ref(1)
		const alertVisible = ref(false)

		if (isLoaded.value) {
			getPage(currPage.value)
			init()
		}

		watch(isLoaded, () => {
			getPage(currPage.value)
			init()
		})

		const updateScore = (payload) => {
			addToScore(payload)
		}

		const showAlert = () => {
			alertVisible.value = true

			setTimeout(function() {
				alertVisible.value = false
			}, 3000)
		}
		
		const nextPage = () => {
			if (currPage.value >= totalPages.value) return

			validate(pageData.value.id, scores.value)

			if (Object.keys(errors.value).length > 0) {
				showAlert()
				return
			}

			currPage.value++
		}

		const prevPage = () => {
			if (currPage.value <= 1 ) return
			currPage.value--
		}

		const isLastPage = () => {
			if (currPage.value === totalPages.value) return true
			return false
		}

		watch(currPage, function(val) {
			getPage(val)
		})

		return { 
			pageData,
			currPage,
			nextPage,
			prevPage,
			totalPages,
			isLastPage,
			errors,
			scores,
			updateScore,
			alertVisible,
			date_f,
			time_f
		}
	}
}
</script>
